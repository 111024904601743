var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c(
            "form",
            {
              staticClass: "needs-validation",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.formSubmit($event)
                }
              }
            },
            [
              _c("b-alert", { attrs: { show: "", variant: "info" } }, [
                _c("i", { staticClass: "mdi mdi-alert-circle-outline mr-2" }),
                _vm._v("Les champs obligatoires sont marqués avec (*) ")
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "formrow-email-input" } }, [
                      _vm._v("Désignation *")
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.typeForm.designation,
                          expression: "typeForm.designation"
                        }
                      ],
                      staticClass: "form-control",
                      class: {
                        "is-invalid": _vm.$v.typeForm.designation.$error
                      },
                      attrs: {
                        id: "projectname",
                        type: "text",
                        required: "",
                        placeholder: ""
                      },
                      domProps: { value: _vm.typeForm.designation },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.typeForm,
                            "designation",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]),
              _c("div", { staticClass: "row" }, [
                _vm.typeForm.uuid
                  ? _c("div", { staticClass: "col text-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-warning",
                          attrs: {
                            disabled: _vm.$v.typeForm.$invalid,
                            type: "submit"
                          }
                        },
                        [_vm._v(" Modifier ")]
                      )
                    ])
                  : _c("div", { staticClass: "col text-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: {
                            disabled: _vm.$v.typeForm.$invalid,
                            type: "button"
                          },
                          on: { click: _vm.formSubmit }
                        },
                        [_vm._v(" Enregistrer ")]
                      )
                    ])
              ])
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }