<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Roomform from "./components/Roomform.vue";

import { getDropDownElementsByTypeApi } from "@/api/common";
import Swal from "sweetalert2";
export default {
  import: {getDropDownElementsByTypeApi},
  page: {
    title: "Types de chambres",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Roomform },
  data() {
    return {
      selectedRoom: null,
      tableData: [],
      List_Room: [],
      data: [],
      rows:'',
      active:false,
      selectedRow:[],
      Room:undefined,
      title: "Types de chambres",
      items: [
        {
          text: "Paramètres",
          href: "javascript:void(0)",
        },
        {
          text: "Types de chambres",
          active: true,
        },
      ],
      totalPages : 0,
      currentPage: 1,
      pageSize: 5,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;
    // fetch divisions from api
    this.fetchRoomsList();
  },
   watch:{
      Room:{
          handler(val){
            this.List_Room.push({
                name:val.name,
                description:val.description
            });
          }
          
          
      }
  },
  methods:{
    
    realodTable(val){
      if(val)
      this.fetchRoomsList()
    },
    fetchRoomsList(){
      getDropDownElementsByTypeApi("hosting_room_classification")
      .then(res => (this.List_Room = res.data.original.list))
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },
    getRoomsTypes() {
      var _this = this;
      getDropDownElementsByTypeApi("hosting_room_classification")
        .then((res) => {
          _this.roomTypes = res.data.original.list;
        })
        .catch((error) => {
          Swal.fire("Erreur!", error.message, "error");
        })
        .finally(() => {});
    },

     deleteTypeRoom(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le type :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/infrastructure/room_types/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  _this.fetchRoomsList();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },


  }
};
</script>

<template>
 <Layout>
    <PageHeader :title="title" :items="items" />
     <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des types </div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <!-- <table class="table table-centered table-nowrap"> -->
                  <v-table
                    class="table table-hover"
                    :data="List_Room"
                    :currentPage.sync="currentPage"
                    :pageSize="5"
                    @totalPagesChanged="totalPages = $event"
                    selectionMode="single"
                    selectedClass="table-info"
                     @selectionChanged="selectedRow = $event"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>Désignation</th>
                      <th>Type</th>
                      <!-- <th>Description</th> -->
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}" >
                    <v-tr v-for="row in displayData" :key="row.id" :row="row">
                      <td>
                        {{ row.designation }}
                      </td>
                       <td>{{ row.type }}</td>
                      <!-- <td>{{ row.description }}</td> -->
                      <td>
                        
                        <a
                          v-if="row.systeme == 0"
                          @click="deleteTypeRoom(row.designation, row.uuid)"
                          class="text-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Supprimer"
                          data-original-title="Delete"
                          ><i class="mdi mdi-close font-size-18"></i
                        ></a>
                      </td> 
                    </v-tr>
                  </tbody>
                </v-table>
                <smart-pagination
                  :currentPage.sync="currentPage"
                  :totalPages="totalPages"
                />
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

            <Roomform :selectedRow="selectedRow" @realodTable="realodTable"  />
      </div>
    </div>
  </Layout>
</template>